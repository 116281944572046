import React, { useState } from "react";
import { View } from "react-native-web";
import Game from "./components/Game";

const App = () => {
	const [gameOver, setGameOver] = useState(false);
	const [resetGame, setResetGame] = useState(false);
	const [isTimerRunning, setIsTimerRunning] = useState(false);

	const handleTimeUp = () => {
		setGameOver(true);
		setIsTimerRunning(false);
	};

	const handleGameEnd = () => {
		setGameOver(true);
		setIsTimerRunning(false);
	};

	const restartGame = () => {
		setGameOver(false);
		setResetGame((prev) => !prev);
		setIsTimerRunning(false);
	};

	const startTimer = () => {
		setIsTimerRunning(true);
	};

	return (
		<View style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
			<Game
				gameOver={gameOver}
				onGameEnd={handleGameEnd}
				resetGame={resetGame}
				onStartTimer={startTimer}
				passTimeStartToParent={(d) => startTimer(d)}
			/>
		</View>
	);
};

export default App;
