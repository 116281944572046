import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";

const Timer = ({ startTime, onTimeUp, isRunning }) => {
	const [time, setTime] = useState(startTime);
	const [dashOffset, setDashOffset] = useState(0);

	useEffect(() => {
		if (isRunning) {
			const totalDashArray = 283; // Circumference of the circle
			const timerId = setInterval(() => {
				setTime((prevTime) => {
					if (prevTime <= 1) {
						clearInterval(timerId);
						onTimeUp();
						return 0;
					}
					const newTime = prevTime - 1;
					setDashOffset((totalDashArray * newTime) / startTime);
					return newTime;
				});
			}, 1000);

			return () => clearInterval(timerId);
		} else {
			setTime(startTime);
			setDashOffset(0);
		}
	}, [isRunning, startTime, onTimeUp]);

	return (
		<View
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "rgba(255, 255, 255, 0.9)", // Semi-transparent background
				padding: "10px",
				borderRadius: "50%",
				boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
			}}>
			<svg width='60' height='60' viewBox='0 0 100 100'>
				{" "}
				{/* Slightly smaller size */}
				<circle
					cx='50'
					cy='50'
					r='45'
					stroke='#e0e0e0'
					strokeWidth='10'
					fill='none'
				/>
				<circle
					cx='50'
					cy='50'
					r='45'
					stroke='#0B84D9'
					strokeWidth='10'
					fill='none'
					strokeDasharray='283'
					strokeDashoffset={dashOffset}
					transform='rotate(-90 50 50)'
				/>
				<text
					x='50'
					y='55'
					textAnchor='middle'
					fontSize='24'
					fontWeight='bold'
					fill='#333'>
					{time}
				</text>
			</svg>
		</View>
	);
};

export default Timer;
